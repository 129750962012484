<template>
  
</template>

<script>
import AppStorage from '../../Helpers/AppStorage'

export default {
    created(){
        AppStorage.clear()
        window.location ='/login'
    }
}
</script>
